<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>内容</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/campus' }"
          >合作校区
        </el-breadcrumb-item>
        <el-breadcrumb-item>新增</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-info">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        size="mini"
      >
        <el-form-item label="校区名称：" label-position="left" prop="name">
          <el-input v-model="form.name" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item
          label="联系人："
          label-position="left"
          prop="contact_name"
        >
          <el-input v-model="form.contact_name" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item
          label="联系手机："
          label-position="left"
          prop="contact_mobile"
        >
          <el-input
            v-model="form.contact_mobile"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="合作人：" prop="partner_id">
          <el-col :span="6">
            <selectUser :role="role" @onSelect="onSelectUser" :selectUserId="form.partner_id"></selectUser>
          </el-col>
        </el-form-item>
        <el-form-item label="合作课程：" label-position="left">
          <selectCourse @success="success" />
          <div class="flex">
            <el-descriptions
              v-for="(item, index) in form.courses.check_course_list"
              :key="item.id"
              :column="2"
              border
              class="margin-top"
              size="mini"
              style="width: 40%; margin: 5%"
              title=""
            >
              <template slot="extra">
                <el-button
                  size="small"
                  @click="delCourse('check_course_list', index)"
                  >删除
                </el-button>
              </template>
              <el-descriptions-item>
                <template slot="label"> 课程名称</template>
                {{ item.name }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 封面</template>
                <el-image :src="imageUrl + item.pic" style="width: 120px" />
              </el-descriptions-item>
              <el-descriptions-item v-if="item.is_all === 0">
                <template slot="label">开始天数</template>
                <el-input-number v-model="item.start_plan_day" :min="0" />
              </el-descriptions-item>
              <el-descriptions-item v-if="item.is_all === 0">
                <template slot="label">结束天数</template>
                <el-input-number v-model="item.end_plan_day" :min="0" />
              </el-descriptions-item>
              <el-descriptions-item :span="2">
                <template slot="label"> 是否全部计划</template>
                <el-switch
                  v-model="item.is_all"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </el-descriptions-item>
              <el-descriptions-item :span="2">
                <template slot="label">更新周期</template>
                <el-checkbox-group v-model="item.update_day">
                  <el-checkbox-button
                    v-for="(city, index) in [
                      '日',
                      '一',
                      '二',
                      '三',
                      '四',
                      '五',
                      '六',
                    ]"
                    :key="city"
                    :label="index"
                    >{{ city }}
                  </el-checkbox-button>
                </el-checkbox-group>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">开始时间</template>
                <el-date-picker
                  v-model="item.start_at"
                  placeholder="选择日期时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="flex">
            <el-descriptions
              v-for="(item, index) in form.courses.course_list"
              :key="item.id"
              :column="3"
              border
              class="margin-top"
              style="width: 40%; margin: 5%"
              title=""
            >
              <template slot="extra">
                <el-button size="small" @click="delCourse('course_list', index)"
                  >删除
                </el-button>
              </template>
              <el-descriptions-item>
                <template slot="label"> 课程名称</template>
                {{ item.name }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 封面</template>
                <el-image :src="imageUrl + item.pic" style="width: 120px" />
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </el-form-item>

        <el-form-item
          label="总金额："
          label-position="left"
          prop="total_amount"
        >
          <el-input-number
            v-model="form.total_amount"
            :min="1"
            style="width: 200px"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="总人数：" label-position="left" prop="total_nums">
          <el-input-number
            v-model="form.total_nums"
            :min="1"
            style="width: 200px"
          ></el-input-number>
        </el-form-item>

        <el-form-item>
          <el-button
            :loading="loading"
            size="small"
            type="primary"
            @click="checkOnSubmit"
            >保存
          </el-button>
          <router-link class="router-link" to="/campus">
            <el-button size="small" style="margin-left: 10px">取消</el-button>
          </router-link>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import selectCourse from "@/components/selectCourse/index.vue";
import selectUser from "@/components/selectUser.vue";
import tools from "@/utils/tools";
import { mapActions } from "vuex";

export default {
  name: "Add",
  components: {
    selectCourse,
    selectUser,
  },
  data() {
    return {
      role: 33,
      loading: false,
      form: {
        partner_id: 0,
        courses: {
          check_course_list: [],
          course_list: [],
        },

        total_amount: 0, // 总金额 必须 两位小数
        total_nums: 0, // 总人数 必须 整形
      },
      imageUrl: config.imageUrl,
      rules: {
        name: [{ required: true, message: "请输入校区名称", trigger: "blur" }],
        contact_name: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        contact_mobile: [
          { required: true, message: "请输入联系人手机号码", trigger: "blur" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
            message: "请输入正确的手机号码或者座机号",
          },
        ],
        total_amount: [
          { required: true, message: "请输入总金额", trigger: "blur" },
        ],
        total_nums: [
          { required: true, message: "请输入总人数", trigger: "blur" },
        ],
        partner_id: [
          { required: true, message: "请选择合作人", trigger: "change" },
        ]
      },
    };
  },
  methods: {
    ...mapActions("collaborate", ["schoolAdd"]),
    delCourse(key, index) {
      this.form.courses[key].splice(index, 1);
    },
    success(data) {
      this.form.courses.course_list.push(
        ...data.course_list.map((v) => {
          return {
            id: v.id,
            name: v.name,
            pic: v.pic,
            indate: v.indate,
            is_work: v.is_work,
          };
        })
      );
      this.form.courses.check_course_list.push(
        ...data.check_course_list.map((value) => {
          return {
            id: value.id,
            name: value.name,
            pic: value.pic,
            is_all: 1,
            end_plan_day: 0,
            start_plan_day: 0,
            update_day: [1, 3, 5],
            start_at: new Date(),
            indate: value.indate,
          };
        })
      );
      this.form.courses.check_course_list = tools.removeDuplicatesBySameId(
        this.form.courses.check_course_list
      );
      this.form.courses.course_list = tools.removeDuplicatesBySameId(
        this.form.courses.course_list
      );
    },
    async verification() {
      const { check_course_list, course_list } = this.form.courses;
      if (check_course_list.length !== 0 || course_list.length !== 0) {
        return true;
      }
      return false;
    },
    checkOnSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (await this.verification()) {
            try {
              this.loading = true;
              const { res_info } = await this.schoolAdd(this.form);
              if (res_info !== "ok") return;
              this.$message.success("创建成功");
              await this.$router.push("/campus");
            } finally {
              this.loading = false;
            }
          }
        }
      });
    },
    onSelectUser(value){
      this.form.partner_id = value
    }
  },
};
</script>

<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}
</style>